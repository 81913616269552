<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form ref="form" :inline="true" :model="formInline" small="size">
      <el-form-item label="字典名称：">
        <el-input
          v-model="formInline.name"
          v-check-permission
          placeholder="请输入字典名称"
        />
      </el-form-item>
      <el-form-item label="字典分类名称：">
        <el-select v-model="formInline.dicTypeCode">
          <el-option
            v-for="item in categoryDicList"
            :key="item.code"
            :value="item.code"
            :label="item.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryData()"> 查询 </el-button>
        <el-button
          v-if="$checkPermission('ADDBASIC', $route)"
          type="primary"
          @click="dataOperation(null, 2)"
        >
          新增
        </el-button>
        <el-button
          v-if="$checkPermission('IMPORTBASIC', $route)"
          type="primary"
          @click="importBasic()"
        >
          导入
        </el-button>
        <el-button type="primary" @click="queryClear()"> 重置 </el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="orderListData"
      border
      class="width-96"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column prop="code" label="字典CODE" align="center" />
      <el-table-column prop="name" label="字典名称" align="center" />
      <el-table-column prop="dicTypeCode" label="字典类型CODE" align="center" />
      <el-table-column prop="dicTypeName" label="字典类型" align="center" />
      <el-table-column
        label="操作"
        show-overflow-tooltip
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            v-if="$checkPermission('EDITBASIC', $route)"
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 0)"
          >
            编辑
          </el-button>
          <el-button
            v-if="
              !scope.row.isDicType && $checkPermission('DELETEBASIC', $route)
            "
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 1)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      :title="titleType + '字典信息'"
      :visible.sync="dialogVisible"
      width="35%"
    >
      <el-form
        v-if="dialogVisible"
        ref="form1"
        label-position="right"
        :model="form1"
        :rules="rules"
        label-width="160px"
        @submit.native.prevent
      >
        <h3 v-if="isDelete" style="margin-bottom: 20px; text-align: center">
          确认删除<span style="color: red">{{ info.name }}</span
          >信息吗？
        </h3>
        <template v-if="!isDelete">
          <el-form-item label="字典分类:" prop="dicTypeCode">
            <el-select
              v-model="form1.dicTypeCode"
              :disabled="titleType === '修改'"
            >
              <el-option
                v-for="item in categoryDicAllList"
                :key="item.code"
                :value="item.code"
                :label="item.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="字典编码:" prop="code">
            <el-input
              v-model="form1.code"
              v-check-permission
              placeholder="请输入字典编码"
              style="width: 80%"
              :disabled="titleType === '修改'"
              maxlength="10"
              @keyup.enter.native="saveProfessional()"
            />
          </el-form-item>
          <el-form-item label="字典名称:" prop="name">
            <el-input
              v-model="form1.name"
              v-check-permission
              placeholder="请输入字典名称"
              style="width: 80%"
              maxlength="20"
              @keyup.enter.native="saveProfessional()"
            />
          </el-form-item>
          <template v-if="titleType != '修改'">
            <el-form-item label="是否为分类:">
              <el-radio-group v-model="form1.isDicType">
                <el-radio :label="false"> 否 </el-radio>
                <el-radio :label="true"> 是 </el-radio>
              </el-radio-group>
            </el-form-item>
          </template>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveProfessional()">确定</el-button>
      </span>
    </el-dialog>
    <importList
      v-if="importDialogVisible"
      :visible="importDialogVisible"
      :title-type="basicDic"
      @cancel="importDialogVisible = false"
      @refreshList="refreshList"
    />
  </div>
</template>

<script>
import { codeRule } from '@/utils/verificationRule.js';
import importList from '@/views/modules/doctorOms/doctorManage/doctorList/component/importData';
export default {
  name: 'ServiceManageList',
  components: {
    importList,
  },
  data() {
    return {
      importDialogVisible: false, // 导入弹框显示
      basicDic: 'basicDic', // 导入
      categoryDicList: [],
      categoryDicAllList: [{ code: 'all', name: '无' }],
      parentDicTypeCode: '',
      loading: false,
      isDelete: false,
      dialogVisible: false, // 弹框显示与否
      titleType: '', // 是新增还是编辑
      formInline: {
        dicTypeCode: '',
        name: '',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      form1: {
        code: '',
        name: '',
        dicTypeCode: '',
        isDicType: false,
      },
      rules: {
        code: [{ required: true, validator: codeRule, trigger: 'blur' }],
        name: [{ required: true, message: '请输入字典名称', trigger: 'blur' }],
        dicTypeCode: [
          { required: true, message: '请选择字典分类', trigger: 'change' },
        ],
      },
      orderListData: [], // 服务列表
    };
  },
  computed: {
    // ...mapState('categoryManage', {
    //   categoryList: state => state.categoryList
    // })
  },
  watch: {
    'form1.dicTypeCode': function (value) {
      if (value) {
        this.categoryDicAllList.forEach((item) => {
          if (item.code === value) {
            this.parentDicTypeCode = item.dicTypeCode;
          }
        });
      }
    },
  },
  mounted() {
    this.getQueryDicTypeList();
    this.getBasicList();
  },
  methods: {
    // 导入基础字典
    importBasic() {
      this.importDialogVisible = true;
    },
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getBasicList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getBasicList();
    },
    // 重置
    queryClear() {
      this.formInline = {
        // 查询条件
        dicTypeCode: '',
        name: '',
      };
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.getBasicList();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.getBasicList();
    },
    // 查询字典分类列表
    getQueryDicTypeList() {
      this.$api.queryDicTypeList({}).then((res) => {
        this.categoryDicList = res.data;
        const list = JSON.parse(JSON.stringify(res.data));
        // console.log(list, 'list');
        list.unshift({
          name: '无',
          code: 'all',
        });
        this.categoryDicAllList = list;
        console.log(list, 'list');
        console.log(this.categoryDicAllList, 'this.categoryDicAllList');
      });
    },
    // 分页查询字典列表
    getBasicList() {
      this.loading = true;
      const params = {
        ...JSON.parse(JSON.stringify(this.formInline)),
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      this.$api
        .pageQueryDic(params)
        .then((res) => {
          this.orderListData = res.data.data;
          this.pageInfo.total = Number(res.data.total); // 获取总共有多少条数据
          this.loading = false;
        })
        .catch(() => {});
    },
    // 导出列表
    /**
     * 列表中数据的操作方法
     * 0 - 查看
     * 1 - 编辑
     * 2 - 删除
     */
    dataOperation(item, type) {
      this.dialogVisible = true;
      this.info = item;
      if (item === null) {
        this.form1 = {
          code: '',
          name: '',
          dicTypeCode: '',
          isDicType: false,
        };
        this.titleType = '新增';
      } else {
        this.form1 = {
          code: item.code,
          name: item.name,
          dicTypeCode: item.dicTypeCode,
          isDicType: item.isDicType,
        };
        this.titleType = type === 1 ? '删除' : '修改';
      }
      this.isDelete = type === 1;
    },
    // 保存职业
    saveProfessional() {
      this.$refs['form1'].validate((valid) => {
        if (valid) {
          if (this.isDelete) {
            this.deleteData();
          } else {
            const param = {
              ...JSON.parse(JSON.stringify(this.form1)),
            };
            param.dicTypeCode =
              this.form1.dicTypeCode === 'all' ? '' : param.dicTypeCode;
            if (this.titleType === '修改') {
              param.id = this.info.id;
            } else {
              param.parentDicTypeCode =
                this.parentDicTypeCode === 'all' ? '' : this.parentDicTypeCode;
            }
            this.$api.editDic(param).then((res) => {
              if (res.code === 0) {
                this.$message({
                  type: 'success',
                  message:
                    this.titleType === '修改'
                      ? '修改字典信息成功'
                      : '新增字典信息成功',
                });
                this.dialogVisible = false;
                this.getBasicList();
                this.getQueryDicTypeList();
                this.$refs['form1'].resetFields();
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    // 删除字典
    deleteData() {
      this.$api.deleteDic({ id: this.info.id }).then((res) => {
        if (res.code === 0) {
          this.$message.success('删除字典成功');
          this.dialogVisible = false;
          this.getBasicList();
          this.getQueryDicTypeList();
        }
      });
    },
    // 刷新列表
    refreshList(value) {
      if (value === 'success') {
        this.pageInfo.page = 1;
        this.getBasicList();
      }
      this.importDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 50%;

  /* 宽度和高度需要相等 */
}

.flex {
  display: flex;
}

.orderStatic {
  padding: 10px 0;
}

.labelStyle {
  width: 8%;
  height: 35px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.active {
  color: #eee;
  background-color: #409eff;
}
</style>
